<template>
  <div>
    <v-dialog
      v-model="canShowDialog"
      max-width="520"
      persistent
      @keydown.esc="closeDialog(true)"
      @keydown.enter="onSignatureUploadConfirm"
    >
      <v-card
        class="v-card"
      >
        <v-card-title
          class="v-card-title"
        >
          <universal-button
            type="back"
            color="transparent"
            style="border: none !important;"
            :append-icon-style="{ fontSize: '35px !important' }"
            class="mr-2"
            @click="closeDialog(false)"
          />
          {{ $t('base.upload_new_signature') }}
          <v-spacer/>

          <div
            class="x-image"
          >
            <v-img
              src="/img/icons/x-icon-image.svg"
              contain
              width="30"
              height="30"
              @click="closeDialog(true)"
            />
          </div>
        </v-card-title>
        <v-card-text
          class="pt-5 v-card-tex m-0t"
        >
          <div
            class="d-flex justify-end align-middle"
          >
            <universal-button
              v-if="config && (config.draw || config.draw === undefined)"
              type="base"
              color="#65AD45"
              :label="$t('base.clear')"
              style="color: #FFFFFF"
              class="mb-3"
              @click="clearSignatureArea"
            />
            <universal-button
              v-else
              type="base"
              color="#65AD45"
              :label="$t('base.upload_signature')"
              style="color: #FFFFFF"
              class="mb-3"
              prepend-icon="mdi-data-matrix-scan"
              @click="triggerSignatureUpload"
            />
          </div>
          <div
            class="signature-image-wrapper"
          >
<!--            Draw signature-->
            <template
              v-if="config && (config.draw || config.draw === undefined)"
            >
              <canvas
                id="canvas"
              />
            </template>
<!--            Upload signature image-->
            <template
              v-else
            >
              <v-img
                :src="uploadedImage && uploadedImage.data ? uploadedImage.data : undefined"
                style="height: 240px;"
                contain
              />
            </template>

          </div>
          <v-checkbox
            v-if="showSaveCheckbox"
            v-model="saveChecked"
            color="#65AD45"
          >
            <template
              #label
            >
              <span
                class="checkbox-label-style"
              >
                {{ $t('base.save_signature_label') }}
              </span>
            </template>
          </v-checkbox>
          <text-field
            v-if="saveChecked"
            v-model="enteredPin"
            autofocus
            :field="pinField"
          />
        </v-card-text>
        <v-card-actions
          class="v-card-actions"
        >
          <v-row
            style="margin: 0;"
          >
            <v-col>
              <universal-button
                type="cancel"
                class="universal-buttons"
                @click="closeDialog(true)"
              />
            </v-col>
            <v-col>
              <universal-button
                type="save"
                :label="$t('base.confirm')"
                class="universal-buttons"
                @click="onSignatureUploadConfirm"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      :accept="'image/*'"
      enctype="multipart/form-data"
      @change="handleSignatureUpload"
    />
  </div>
</template>
<script>
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import { forEach, isEmpty } from 'lodash'
import store from '@/global/store'
import { api } from '@/global/services/api'
import TextField from '@/global/components/view-layouts/tabs-layout/components/fields/TextField.vue'

export default {
  name: 'CreateSignatureDialog',

  components: {
    TextField,
    UniversalButton
  },

  emits: [
    'close',
    'confirm',
    'refresh-instance'
  ],

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ({})
    },
    showSaveCheckbox: {
      type: Boolean,
      default: true
    },
    signatureType: {
      type: String,
      default: ''
    },
    thirdPartySignature: {
      type: Boolean,
      default: false
    },
    instance: {
      type: Object,
      default: null
    },
    additionalObject: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      uploadedImage: null,
      saveChecked: false,
      canvas: null,
      context: null,
      isDrawing: false,
      x: 0,
      y: 0,
      offsetX: undefined,
      offsetY: undefined,
      ongoingTouches: [],
      canShowDialog: false,
      enteredPin: null,
      pinField: {
        key: 'pin',
        label: this.$t('base.pin_code'),
        field_type: 'password'
      }
    }
  },

  watch: {
    showDialog: {
      immediate: true,
      handler (val) {
        this.canShowDialog = val
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      if (this.config && (this.config.draw || this.config.draw === undefined)) {
        this.startup()
      }
    })
  },

  methods: {
    closeDialog (closeAll) {
      this.$emit('close', closeAll)
    },

    async onSignatureUploadConfirm () {
      try {
        if (this.config && (this.config.draw || this.config.draw === undefined)) {
          const imageBase64 = this.canvas.toDataURL()
          const imageFile = imageBase64 ? new File([imageBase64], 'signature_image', { type: 'image/png' }) : null

          if (this.thirdPartySignature) {
            this.$emit('confirm', imageFile)
          }
          else {
            const { module, method, route } = this.config?.apiConfig?.post || {}
            const { tabsContentInstance } = this.config || {}

            if (module && method && route) {
              const formData = new FormData()
              formData.append('signature_image', imageFile)
              formData.append('pin', this.enteredPin)
              formData.append('travel_document_id', this.$route.params.id)
              formData.append('save_signature', this.saveChecked)
              formData.append('signature_type', this.signatureType)

              if (!isEmpty(this.additionalObject)) {
                for (const key in this.additionalObject) {
                  formData.append(key, this.additionalObject[key] ?? '')
                }
              }

              const { data } = await api()[module][method](route, formData)
              // Check if user is successfully signed document
              if (data && data.signed) {
                this.$emit('close', true)

                // Refresh page data to display new created signature
                if (tabsContentInstance) {
                  const { fetchTabData } = tabsContentInstance || {}
                  if (typeof fetchTabData === 'function') {
                    fetchTabData()
                  }
                }

                // Close all dialogs after successfully signing
                this.closeDialog(true)
                if (this.instance) {
                  this.$emit('refresh-instance', this.instance)
                }
              }
              // TODO: Otherwise, document is not successfully signed, push notification
              else {
              }
            }
          }
        }
      }
      catch (exception) {
        console.log(exception)
      }
    },

    triggerSignatureUpload () {
      this.$refs.fileInput.click()
      this.$refs.fileInput.value = ''
    },

    handleSignatureUpload (event) {
      const uploadedImages = event.target.files
      let invalidMessage = ''

      // Checks are uploaded files have correct formats (only images are allowed)
      forEach(uploadedImages, uploadedImage => {
        if (!uploadedImage.type.startsWith('image/')) {
          invalidMessage += this.$t('fleet/vehicle.not_valid_image_format_message').replace(':image', uploadedImage.name)
        }
      })

      if (!invalidMessage) {
        forEach(uploadedImages, uploadedImage => {
          const reader = new FileReader()
          reader.readAsDataURL(uploadedImage)
          reader.onload = e => {
            this.uploadedImage = {
              data: e.target.result,
              name: uploadedImage.name
            }
          }
        })
      }
      else {
        store.dispatch('base/notifications/push', invalidMessage)
      }
    },

    // Canvas drawing signature start
    startup () {
      this.canvas = document.getElementById('canvas')
      this.canvas.width = document.getElementsByClassName('signature-image-wrapper')[0].clientWidth
      this.canvas.height = document.getElementsByClassName('signature-image-wrapper')[0].clientHeight
      this.context = this.canvas.getContext('2d')
      this.canvas.addEventListener('touchstart', this.handleStart)
      this.canvas.addEventListener('touchend', this.handleEnd)
      this.canvas.addEventListener('touchcancel', this.handleCancel)
      this.canvas.addEventListener('touchmove', this.handleMove)

      this.canvas.addEventListener('mousedown', (e) => {
        this.x = e.offsetX
        this.y = e.offsetY
        this.isDrawing = true
      })

      this.canvas.addEventListener('mousemove', (e) => {
        if (this.isDrawing) {
          this.drawLine(this.context, this.x, this.y, e.offsetX, e.offsetY)
          this.x = e.offsetX
          this.y = e.offsetY
        }
      })

      this.canvas.addEventListener('mouseup', (e) => {
        if (this.isDrawing) {
          this.drawLine(this.context, this.x, this.y, e.offsetX, e.offsetY)
          this.x = 0
          this.y = 0
          this.isDrawing = false
        }
      })
    },

    handleStart (evt) {
      evt.preventDefault()
      const touches = evt.changedTouches
      this.offsetX = this.canvas.getBoundingClientRect().left
      this.offsetY = this.canvas.getBoundingClientRect().top
      for (let i = 0; i < touches.length; i++) {
        this.ongoingTouches.push(this.copyTouch(touches[i]))
      }
    },

    handleMove (evt) {
      evt.preventDefault()
      const touches = evt.changedTouches
      for (let i = 0; i < touches.length; i++) {
        const color = document.getElementById('selColor').value
        const idx = this.ongoingTouchIndexById(touches[i].identifier)
        if (idx >= 0) {
          this.context.beginPath()
          this.context.moveTo(this.ongoingTouches[idx].clientX - this.offsetX, this.ongoingTouches[idx].clientY - this.offsetY)
          this.context.lineTo(touches[i].clientX - this.offsetX, touches[i].clientY - this.offsetY)
          this.context.lineWidth = document.getElementById('selWidth').value
          this.context.strokeStyle = color
          this.context.lineJoin = 'round'
          this.context.closePath()
          this.context.stroke()
          this.ongoingTouches.splice(idx, 1, this.copyTouch(touches[i]))
        }
      }
    },

    handleEnd (evt) {
      evt.preventDefault()
      const touches = evt.changedTouches
      for (let i = 0; i < touches.length; i++) {
        const color = document.getElementById('selColor').value
        const idx = this.ongoingTouchIndexById(touches[i].identifier)
        if (idx >= 0) {
          this.context.lineWidth = document.getElementById('selWidth').value
          this.context.fillStyle = color
          this.ongoingTouches.splice(idx, 1)
        }
      }
    },

    handleCancel (evt) {
      evt.preventDefault()
      const touches = evt.changedTouches
      for (let i = 0; i < touches.length; i++) {
        const idx = this.ongoingTouchIndexById(touches[i].identifier)
        this.ongoingTouches.splice(idx, 1)
      }
    },

    copyTouch ({ identifier, clientX, clientY }) {
      return { identifier, clientX, clientY }
    },

    ongoingTouchIndexById (idToFind) {
      for (let i = 0; i < this.ongoingTouches.length; i++) {
        const id = this.ongoingTouches[i].identifier
        if (id === idToFind) {
          return i
        }
      }

      return -1
    },

    drawLine (context, x1, y1, x2, y2) {
      this.context.beginPath()
      this.context.strokeStyle = '#030712'
      this.context.lineWidth = 3
      this.context.lineJoin = 'round'
      this.context.moveTo(x1, y1)
      this.context.lineTo(x2, y2)
      this.context.closePath()
      this.context.stroke()
    },

    clearSignatureArea () {
      this.context.setTransform(1, 0, 0, 1, 0, 0)
      this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height)
    }
  }
}
</script>

<style scoped lang="scss">
.v-card {
  max-width: 520px;
  border-radius: 16px;
}

.v-card-text {
  background-color: #FFFFFF;
  padding: 2% 2% 0 2% !important;
}

.v-card-title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 32px;
  color: #111827 !important;
  border-bottom: 1px solid #E5E7EB;
}

.v-card-actions {
  font-size: 20px;
  border-top: 1px solid #FFFFFF;
  padding: 0 2% 0 2% !important;
}

.universal-buttons {
  width: 100%;
  height: 40px !important;
}

.signature-image-wrapper {
  position: relative;
  width: 100%;
  height: 240px;
  border-radius: 16px;
  border: 1px solid #E5E7EB;
}

.checkbox-label-style {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
  color: #111827;
  line-height: 20px;
  font-weight: 500;
}

.x-image:hover {
  cursor: pointer;
}
</style>
